import React, { useState, useEffect } from "react";
import Link from "../../../core/Components/Link";

export default function AnchorNav({ fields }) {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelected] = useState("");
  const selectHandler = (id) => {
    setSelected(id);
    setIsOpen(false);
  };
  const [showButton, setShowButton] = useState(false);
  const [position, setPosition] = useState();
  const stickyScroll = () => {
    const header = document.getElementById("stickyAnchor");
    if (window.pageYOffset > position) {
      header.classList.add("is-sticky");
    } else {
      header.classList.remove("is-sticky");
    }
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    const header = document.getElementById("stickyAnchor");
    if (header) {
      const sticky = header.offsetTop;
      setPosition(sticky);
    }
    window.addEventListener("scroll", stickyScroll);

    return () => {
      window.removeEventListener("scroll", stickyScroll);
    };
  }, [stickyScroll]);

  return (
    <div className="anchor-nav" id="stickyAnchor">
      {isOpen ? (
        <div
          className="mobile-background"
          aria-hidden="true"
          tabIndex="0"
          onClick={() => setIsOpen(false)}
        />
      ) : null}
      <div className="l-container z-index-2">
        <input
          className={`menu-btn ${isOpen ? "active" : ""}`}
          type="checkbox"
          id="menu-btn"
        />
        <button
          type="button"
          className="menu-icon"
          htmlFor="menu-btn"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span>{fields.jumpToLabel.fields.Phrase.value}</span>
        </button>
        {fields.getStarted.value.href ? (
          <Link
            field={fields.getStarted}
            className={`cta-button tertiary get-started ${
              showButton ? "show" : ""
            }`}
          />
        ) : null}
        <ul className="menu">
          {fields.anchorLinks.map((i) => (
            <li
              key={i.id}
              className={`link-item ${i.id === selectedItem ? "selected" : ""}`}
            >
              <Link field={i.fields.link} onClick={() => selectHandler(i.id)} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
